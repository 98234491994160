import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

export const heroSlider = () => {
    const splide = new Splide('.splide', {
        type: 'loop',
        drag: 'free',
        focus: 'center',
        pagination: false,
        pauseOnHover: false,
        drag: true,
        perPage: 1,
        arrows: false,
        autoScroll: {
            speed: 2.4,
        },
        autoWidth: true,
        breakpoints: {
            768: {
                autoWidth: false,
            }
        }
    }).mount({ AutoScroll }),
        next = document.querySelector('.next'),
        prev = document.querySelector('.prev')

    if (prev) {
        prev.addEventListener('click', () => {
            splide.go('-1');
        })
    }

    if (next) {
        next.addEventListener('click', () => {
            splide.go('+1');
        })
    }
}