'use strict';

// postcss stylesheets
import './../styles/_tailwind.pcss'
import './../styles/main.pcss'

import global from './_global';
import animations from './_animations';
import { cursor } from "./_cursor";
import { heroSlider } from "./_sliders"

const lazyLoading = () => import('./_lazy-load')

document.addEventListener("DOMContentLoaded", () => {
  global.pageLoadedChecker()
  global.getSreenSize()
  heroSlider()
  animations.setCurrentTime()
  animations.scrollReveal()
  animations.backToTop()
  /*animations.headerBlend()*/
  animations.splitAndRevealText()
  cursor()
  lazyLoading().then((module) => module.init())
})

document.addEventListener('everyThingIsLoaded', () => {
   animations.marquee()
})

